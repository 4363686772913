.home_mail {
  position: fixed;
  top: 50%;
  left: 50px;
  transform: translate(-50%,-50%) rotate(-90deg);
  z-index: 4;
  a {
    letter-spacing: 1px;
    text-decoration: none;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right,#ef6c00 50%,#00bcd4 50%);
    background-size: 200% 100%;
    background-position: 100%;
    transition: all 1s cubic-bezier(0, 0, 0.5, 1);
    font-weight: 700;
    &:hover {
      background-position: 0;
    }
  }
}

.font {
  &12 {
    font-size: 0.650rem; //12px
  }
  &14 {
    font-size: 0.875rem; //14px
  }
  &16 {
    font-size: 1rem; //16px
  }
  &18 {
    font-size: 1.125rem; //18px
  }
}

.m {
  &-auto {
    margin: 0 auto;
  }
  &-0 {
    margin: 0;
  }
  &t- {
    &1 {
      margin-top: 10px;
    }
  }
  &r- {
    &1 {
      margin-right: 10px;
    }
  }
  &b- {
    &0 {
      margin-bottom: 0;
    }
    &1 {
      margin-bottom: 10px;
    }
  }
  &l- {
    &1 {
      margin-left: 10px;
    }
  }
}

.d- {
  &flex {
    display: flex;
  }
  &block {
    display: block;
  }
}