/*------------------------------------
 *Author:amitX
 *Template:amitX
 *Version:1.1
 *-------------------------------------
 */

* {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  line-height: 26px;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.8px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
}

h1 {
  font-size: 54px;
  color: #181818;
  font-weight: 600;
}

h2 {
  font-size: 48px;
  color: #181818;
  font-weight: 600;
}

h3 {
  font-size: 42px;
  color: #181818;
  font-weight: 600;
}

h4 {
  font-size: 36px;
  color: #181818;
  font-weight: 600;
}

h5 {
  font-size: 30px;
  color: #181818;
  font-weight: 600;
}

h6 {
  font-size: 24px;
  color: #181818;
  font-weight: 600;
}

.section-title {
  font-size: 40px;
  margin: 0px 20px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 800;
  color: #fff;
  font-family: "Martel", serif;
  margin-top: 10px;
}

.title-small {
  position: absolute;
  /* transform: rotate(-90deg); */
  top: 50%;
  width: 280px;
  left: -83px;
}
.title-small p {
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.17em;
  text-align: center;
  transform: rotate(-90deg);
  position: relative;
  display: block;
}
.title-small p::before {
  content: '';
  width: 32px;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 28px;
  top: 12px;
}

.title-small p::after {
  content: '';
  width: 32px;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 12px;
  right: 32px;
}

.bold {
  font-weight: 700;
}

a {
  color: #000;
}

a:hover {
  text-decoration: none;
  color: #000;
}

a:focus {
  text-decoration: none;
  outline: 0;
  color: #000;
}

.padding-style1 {
  padding: 100px 0;
}

.padding-style2 {
  padding: 80px 0;
}

.animation {
  opacity: 0;
}

.btn-style1 {
  padding: 10px 22px;
  background-color: #000;
  color: #fff;
  display: inline-block;
  transition: all 0.5s;
  border: none;
  font-weight: 600;
}

.btn-style1:hover,
.btn-style1:focus {
  background-color: #5a5a5a;
}

.btn-style2 {
  padding: 7px 22px;
  color: #fff;
  display: inline-block;
  transition: all 0.5s;
  font-weight: 600;
  background: #4b4b4b;
}

.btn-style2:hover,
.btn-style2:focus {
  background-color: #a2a2a2;
}

.btn-style3 {
  padding: 8px 22px;
  background-color: #fff;
  color: #000;
  display: inline-block;
  transition: all 0.5s;
  font-weight: 600;
}

.btn-style3:hover,
.btn-style3:focus {
  background-color: #4b4b4b;
  color: #fff;
}

.preloader {
  background: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}

.preloader-img {
  background-image: url(../images/loading.gif);
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  left: 50%;
  margin: -100px 0 0 -100px;
  position: absolute;
  top: 50%;
  width: 200px;
}

.display-table {
  display: table;
  width: 100%;
  height: 100%;
}

.display-content {
    vertical-align: middle;
    display: table-cell;
    padding: 20px 0;
}

#pp-nav li .active span,
.pp-slidesNav .active span {
  background: #fff;
}

.content-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

input::placeholder {
  color: #424242;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.8px;
}

#pp-nav span, .pp-slidesNav span {
  top: 0;
  width: 10px;
  height: 10px;
  border: 0;
  background: rgba(0,0,0,0);
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  background: #909090;
}

#pp-nav li, .pp-slidesNav li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 17px;
  position: relative;
}
.vanta-canvas {
  height: 100% !important;
  width: 120% !important;
}
#welcome-vanta-area {
  height: 100%;
  overflow: hidden;
}